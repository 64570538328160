import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {LoadingService} from "@lib/services/loading.service";
import {RouteService} from "@juulsgaard/ngx-tools";
import {RefreshTokenAuthService} from "@lib/services/refresh-token-auth.service";

export function embeddedAuthGuard(standaloneSupport: boolean): CanActivateFn {
  return async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

    const authService = inject(RefreshTokenAuthService);
    const router = inject(Router);

    if (authService.standalone) {

      // Block standalone access if not supported
      if (!standaloneSupport) {
        await router.navigate(['error'], {
          skipLocationChange: true,
          queryParams: {reason: 'This feature is not available in the app'}
        });

        return false;
      }

      return true;
    }

    const routeService = inject(RouteService);
    routeService.prepopulate(route);

    const loadingService = inject(LoadingService);
    const scope = loadingService.startLoad();

    try {

      await authService.getRefreshToken();

    } catch (e: any) {

      await router.navigate(['error'], {
        skipLocationChange: true,
        queryParams: {reason: e.message}
      });

      return false;

    } finally {
      scope.dispose();
    }

    return true;
  };
}
