import {ChangeDetectionStrategy, Component, inject, signal} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-sso-redirect',
  standalone: true,
  templateUrl: './sso-redirect.component.html',
  styleUrl: './sso-redirect.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsoRedirectComponent {

  constructor() {
    const route = inject(ActivatedRoute).snapshot;

    const token = route.queryParams['token'];
    if (!token) {
      this.setError();
      return;
    }

    const parent = window.parent;
    if (parent === window) {
      this.setError();
      return;
    }

    try {
      parent.postMessage({type: 'sso-token', token}, '*');
    } catch {
      this.setError();
    }
  }

  error = signal<string|undefined>(undefined)
  setError() {
    this.error.set('Something went wrong');
  }
}
