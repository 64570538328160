import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from '@environments/environment';

import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import arraySupport from 'dayjs/plugin/arraySupport'

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(arraySupport);

if (environment.production) {
  enableProdMode();
} else {
  environment.apiHost = environment.apiHost.replace('localhost', location.hostname);
  environment.storageUrl = environment.storageUrl.replace('localhost', location.hostname);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
