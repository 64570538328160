import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {RouteReuseStrategy, RouterModule} from "@angular/router";
import {appRoutes} from "./app.routes";
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '@environments/environment';
import {CoreModule} from "@core/core.module";
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {urlInterceptor} from "@core/interceptors/url.interceptor";
import {LocaleService} from "@core/services/locale.service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {IndexedDbAdapter} from "@juulsgaard/store-service";
import {NgxServiceWorkerModule} from "@juulsgaard/ngx-service-worker";
import {
  NgxDialogOutletModule, NgxOverlayOutletModule, NgxSideMenuOutletModule, NgxSnackbarOutletModule, UIScopeContext
} from "@juulsgaard/ngx-material";
import {IconProviderModule} from "@juulsgaard/ngx-tools";
import {uiScopeConfig} from "@lib/models/ui-scopes";
import {CustomRouteReuseStrategy} from "@lib/routing/custom-route-reuse.strategy";
import {DefaultFormInputRegistryService} from "@juulsgaard/ngx-forms-inputs";
import {exceptionInterceptor} from "@core/interceptors/exception.interceptor";

@NgModule({
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorker,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgxServiceWorkerModule.register(environment.serviceWorker),
    IconProviderModule.WithFontAwesome(),
    NgxDialogOutletModule,
    NgxOverlayOutletModule.WithBaseProviders(UIScopeContext.Provide(uiScopeConfig)),
    NgxSnackbarOutletModule,
    NgxSideMenuOutletModule
  ],
  providers: [
    LocaleService.Provide(),
    {provide: IndexedDbAdapter, useValue: new IndexedDbAdapter()},
    {provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy},
    DefaultFormInputRegistryService.Provide(),
    provideHttpClient(withInterceptors([
      exceptionInterceptor,
      urlInterceptor
    ]))
  ]
})
export class AppModule {
}
