import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {Loading} from "@juulsgaard/rxjs-tools";
import {ActivatedRoute} from "@angular/router";
import {CodexLeaderboard} from "../../../../ar-codex/models/ar-codex.models";
import {Media} from "@lib/models/media-urls";
import {CommonModule} from "@angular/common";
import {LoadingOverlayComponent, MaterialTheme} from "@juulsgaard/ngx-material";
import {ImageFallbackDirective, NgxTheme, TruthyPipe} from "@juulsgaard/ngx-tools";
import {ArCodexAnonClient} from "../../services/ar-codex-anon.client";

@Component({
  selector: 'app-ar-codex-leaderboard',
  templateUrl: './ar-codex-leaderboard.component.html',
  styleUrls: ['./ar-codex-leaderboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ImageFallbackDirective, LoadingOverlayComponent, TruthyPipe]
})
export class ArCodexLeaderboardComponent implements OnInit {

  loading$ = Loading.Empty();
  leaderboard: CodexLeaderboard[] = [];

  constructor(
    private client: ArCodexAnonClient,
    private route: ActivatedRoute,
    private changes: ChangeDetectorRef,
    private element: ElementRef<HTMLElement>
  ) {
    MaterialTheme.ApplyTheme(this.element.nativeElement, '#001965', '#5C96E3');
    NgxTheme.ApplyTheme(this.element.nativeElement, '#001965', '#5C96E3');
  }

  ngOnInit() {
    this.load();
  }

  load() {
    if (this.loading$.loading) return;
    const codexId = this.route.snapshot.params['codexId'];
    if (!codexId) return;
    this.loading$ = Loading.Async(this.client.loadLeaderboard(codexId)).then(leaderboard => {
      this.leaderboard = leaderboard;
      this.changes.detectChanges();
    })
  }

  getImageUrl(user: CodexLeaderboard) {
    return Media.UserProfile(user.userId, 'small', user.profileUploadedAt);
  }
}
