import {HttpInterceptorFn} from '@angular/common/http';
import {environment} from "@environments/environment";

export const urlInterceptor: HttpInterceptorFn = (req, next) => {

  if (req.url.startsWith('api://')) {
    req = req.clone({
      url: req.url.replace('api://', environment.apiHost),
      setHeaders: {'ngsw-bypass': `1`}
    });
  }

  return next(req);
};
