import {Routes} from "@angular/router";
import {HomeComponent} from "@core/components/home/home.component";
import {ErrorPageComponent} from "@core/components/error-page/error-page.component";
import {PageNotFoundComponent} from "@core/components/page-not-found/page-not-found.component";
import {IdTokenGuard} from "@lib/routing/id-token.guard";
import {ArCodexLeaderboardComponent} from "./standalone/codex/components/ar-codex-leaderboard/ar-codex-leaderboard.component";
import {embeddedAuthGuard} from "@lib/routing/embedded-auth.guard";
import {EmbeddedAuthService} from "@lib/services/embedded-auth.service";
import {RefreshTokenAuthService} from "@lib/services/refresh-token-auth.service";
import {StandaloneAuthService} from "@lib/services/standalone-auth.service";
import {SsoRedirectComponent} from "./standalone/sso-redirect/sso-redirect.component";

const componentRoutes: Routes = [
  {
    path: 'codex/:codexId/leaderboard',
    component: ArCodexLeaderboardComponent,
    children: []
  }
];

const feaures: Routes = [
  {
    path: 'leaderboard',
    loadChildren: () => import('./leaderboard/leaderboard.module').then(x => x.LeaderboardModule),
    canActivate: [IdTokenGuard]
  },
  {
    path: 'agenda',
    loadChildren: () => import('./agenda/agenda.module').then(x => x.AgendaModule),
    canActivate: [embeddedAuthGuard(false)]
  },
  {
    path: 'codex',
    loadChildren: () => import('./ar-codex/ar-codex.module').then(x => x.ArCodexModule),
    canActivate: [embeddedAuthGuard(true)]
  },
  {
    path: 'bookmarks',
    loadChildren: () => import('./ar-bookmarks/ar-bookmarks.module').then(x => x.ArBookmarksModule),
    canActivate: [embeddedAuthGuard(false)]
  },
  {
    path: 'visualise',
    loadChildren: () => import('./data-rendering/data-rendering.module').then(x => x.DataRenderingModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then(x => x.SupportModule),
    canActivate: [embeddedAuthGuard(false)]
  },
  {
    path: 'moderator',
    loadChildren: () => import('./moderator/moderator-admin/moderator-admin.module').then(x => x.ModeratorAdminModule),
    canActivate: [embeddedAuthGuard(true)]
  },
  {
    path: 'qa',
    loadChildren: () => import('./moderator/moderator-user/moderator-user.module').then(x => x.ModeratorUserModule),
    canActivate: [embeddedAuthGuard(false)]
  },
]

export const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'sso',
    children: [
      {
        path: 'connect',
        data: {connect: true},
        component: SsoRedirectComponent
      }
    ]
  },
  // TODO: Remove after deprecation (use /embed/ instead)
  {
    path: '',
    providers: [
      RefreshTokenAuthService.Provide(EmbeddedAuthService)
    ],
    children: [
      ...componentRoutes,
      ...feaures,
    ]
  },
  {
    path: 'embed',
    providers: [
      RefreshTokenAuthService.Provide(EmbeddedAuthService)
    ],
    children: [
      ...componentRoutes,
      ...feaures
    ]
  },
  {
    path: 'app',
    providers: [
      RefreshTokenAuthService.Provide(StandaloneAuthService)
    ],
    children: [
      ...feaures
    ]
  },
  {
    path: 'error',
    component: ErrorPageComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];
