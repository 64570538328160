import {Injectable} from '@angular/core';
import {CodexLeaderboard} from "../../../ar-codex/models/ar-codex.models";
import {RequestClient} from "@lib/data-models/request-client";

@Injectable({providedIn: 'root'})
export class ArCodexAnonClient {

  constructor(private client: RequestClient) {
  }

  loadLeaderboard(codexId: string) {
    return this.client.get(`api://ar/codex/${codexId}/leaderboard`)
      .go<CodexLeaderboard[]>();
  }
}
