import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Disposable} from "@juulsgaard/ts-tools";
import {distinctUntilChanged} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class LoadingService {

  private scopes = new Set<Disposable>;
  private _loading$ = new BehaviorSubject(false);
  loading$ = this._loading$.pipe(distinctUntilChanged());

  constructor() {
  }

  startLoad(): Disposable {
    const scope: Disposable = {
      dispose: () => this.endLoad(scope)
    };
    this.scopes.add(scope);
    this._loading$.next(this.scopes.size > 0);
    return scope;
  }

  private endLoad(scope: Disposable) {
    this.scopes.delete(scope);
    this._loading$.next(this.scopes.size > 0);
  }
}
