import {inject, Injectable, NgZone} from "@angular/core";
import {firstValueFrom, fromEvent, Observable, of} from "rxjs";
import {first, map} from "rxjs/operators";
import {latestValueFromOrDefault, notNull, permanentCache, persistentCache, timeoutDefault} from "@juulsgaard/rxjs-tools";
import {parseJwt, Timespan} from "@juulsgaard/ts-tools";
import {RouteService} from "@juulsgaard/ngx-tools";

@Injectable({providedIn: 'root'})
export class IdTokenService {

  private readonly inFrame = window.parent !== window;

  private readonly routeService = inject(RouteService);
  private readonly zone = inject(NgZone);

  readonly idToken$ = new Observable<IdToken|undefined>(subscriber => this.getIdToken$().subscribe(subscriber)).pipe(
    permanentCache()
  );

  get idToken(): IdToken | undefined {
    return latestValueFromOrDefault(this.idToken$, undefined);
  }

  async getIdToken(): Promise<IdToken|undefined> {
    return await firstValueFrom(this.idToken$);
  }

  private getIdToken$(): Observable<IdToken | undefined> {

    const queryToken = this.routeService.getQuery('token')();
    if (queryToken) return of(this.processIdToken(queryToken, 'query'));

    if (!this.inFrame) return of(undefined);

    return new Observable<string>(subscriber =>
      this.zone.runOutsideAngular(() => {

        window.parent.postMessage('app-loaded', '*');

        return fromEvent<MessageEvent>(window, 'message').pipe(
          map(event => event.data.token),
          notNull()
        ).subscribe(x => this.zone.run(() => subscriber.next(x)));

      })
    ).pipe(
      map(token => this.processIdToken(token, 'message')),
      timeoutDefault(Timespan.seconds(5), undefined),
      first(),
      persistentCache()
    );
  }

  private processIdToken(tokenStr: string, source: 'message'|'query'): IdToken | undefined {
    const data = parseJwt<IdTokenData>(tokenStr);

    if (!data) {
      console.warn('Received an invalid token. Token was not recognised as a JWT token.');
      return undefined;
    }

    let id = data["x-sub"] ?? data.sub;

    if (!id) {
      console.warn('Received an invalid token. Identifying information not found in JWT payload.');
      return undefined;
    }

    const scope = data.EventId;

    if (scope) id += `_${scope}`;

    const admin = data["x-admin"] === "1";
    if (admin) id += `_admin`;

    return {id, source, token: tokenStr};
  }
}

export interface IdToken {
  id: string;
  source: 'message'|'query';
  token: string;
}

interface IdTokenData {
  'x-sub'?: string;
  email?: string;
  username?: string;
  sub?: string;
  ClientId?: string;
  EventId?: string;
  'x-admin'?: string;
}
