<div class="wrapper">
  <table>
    <thead>
    <tr>
      <th class="rank">Rank</th>
      <th class="picture"></th>
      <th class="name">Name</th>
      <th class="points">Scans</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of leaderboard; let i = index">
      <td class="rank">
        <span>{{i + 1}}.</span>
      </td>
      <td class="picture">
        <img *ngIf="user.profileUploadedAt" [src]="getImageUrl(user)" [alt]="user.altName" fallbackSrc="assets/placeholders/profile.png" loading="lazy">
        <i *ngIf="!user.profileUploadedAt" class="far fa-user-circle"></i>
      </td>
      <td class="name">{{user.firstName}} {{user.lastName}} <span *ngIf="user.altName">[{{user.altName}}]</span></td>
      <td class="points">
        <b>{{user.scans}}</b>
      </td>
    </tr>
    </tbody>

  </table>
</div>

<ngx-loading-overlay type="fixed" *ngIf="loading$ | truthy"/>
