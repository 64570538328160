import {inject, Injectable} from '@angular/core';
import {IdToken, IdTokenService} from "@lib/services/id-token.service";
import {BehaviorSubject, from, lastValueFrom, Observable, of, retry, startWith, switchMap} from "rxjs";
import {first, map} from "rxjs/operators";
import {permanentCache} from "@juulsgaard/rxjs-tools";
import {LocalStorage} from "@lib/services/local-storage.service";
import {SessionStorage} from "@lib/services/session-storage.service";
import {HttpClient} from "@angular/common/http";
import {parseJwt} from "@juulsgaard/ts-tools";
import {UserScopeService} from "@lib/services/user-scope.service";
import {RefreshTokenAuthService, RefreshTokenData} from "@lib/services/refresh-token-auth.service";

@Injectable()
export class EmbeddedAuthService extends RefreshTokenAuthService {

  private static REFRESH_TOKEN_KEY = 'embeddedRefreshToken';

  private readonly localStorage = inject(LocalStorage);
  private readonly sessionStorage = inject(SessionStorage);
  private readonly httpClient = inject(HttpClient);
  private readonly tokenService = inject(IdTokenService);
  private readonly userScope = inject(UserScopeService);

  readonly standalone = false;

  readonly source: BehaviorSubject<Observable<RefreshTokenData|undefined>>;

  readonly tokenData$: Observable<RefreshTokenData | undefined>;

  readonly currentTokenData$: Observable<RefreshTokenData>;
  readonly currentRefreshToken$: Observable<string>;
  readonly currentUserId$: Observable<string>;

  constructor() {
    super();

    this.source = new BehaviorSubject(this.generateSource());

    this.currentTokenData$ = this.source.pipe(
      first(),
      switchMap(x => x),
      map(data => {
        if (!data) throw new Error('ID Token not received');
        return data;
      })
    );

    this.currentRefreshToken$ = this.currentTokenData$.pipe(map(x => x.token));
    this.currentUserId$ = this.currentTokenData$.pipe(map(x => x.userId));

    this.tokenData$ = this.source.pipe(
      switchMap(x => x.pipe(startWith(undefined)))
    );
  }

  private generateSource() {
    return this.tokenService.idToken$.pipe(
      switchMap(token => token ? from(this.provisionRefreshToken(token)) : of(undefined)),
      permanentCache()
    );
  }

  private async provisionRefreshToken(idToken: IdToken): Promise<RefreshTokenData> {

    // Look for an existing session
    let localToken = this.getSessionToken();

    // If session token doesn't match ID token, invalidate it
    if (localToken && localToken.identityId !== idToken.id) localToken = undefined;

    // If valid session exists, use that token
    if (localToken) {
      this.userScope.joinScope(localToken.userId);
      return localToken;
    }

    // Look for an existing global session
    let globalToken = this.getGlobalToken();

    // If global session token doesn't match ID token, invalidate it
    if (globalToken && globalToken.identityId !== idToken.id) globalToken = undefined;

    // If no valid global session exists, create one
    if (!globalToken) {
      globalToken = await this.generateToken(idToken);
      this.localStorage.tryWrite(EmbeddedAuthService.REFRESH_TOKEN_KEY, this.formatToken(globalToken));
      await this.userScope.createScope(globalToken.userId);
    } else {
      this.userScope.joinScope(globalToken.userId);
    }

    // Save session as local session
    this.sessionStorage.tryWrite(EmbeddedAuthService.REFRESH_TOKEN_KEY, this.formatToken(globalToken));

    return globalToken;
  }

  private getSessionToken(): RefreshTokenData | undefined {
    return this.readToken(this.sessionStorage, EmbeddedAuthService.REFRESH_TOKEN_KEY);
  }

  private getGlobalToken(): RefreshTokenData | undefined {
    return this.readToken(this.localStorage, EmbeddedAuthService.REFRESH_TOKEN_KEY);
  }

  //<editor-fold desc="Session Management">

  private async generateToken(idToken: IdToken): Promise<RefreshTokenData> {

    const request = this.httpClient.post<{ token: string }>(
      `api://auth/user/login/id-token`,
      {},
      {headers: {'Authorization': `Bearer ${idToken.token}`}}
    ).pipe(
      retry({count: 2, delay: 5000}),
      map(x => x.token)
    );

    const token = await lastValueFrom(request);

    const tokenData = parseJwt<{ sub?: string, exp?: number }>(token);
    if (!tokenData.sub) throw Error("Invalid Refresh token received");
    if (!tokenData.exp) throw Error("Invalid Refresh token received");

    return {identityId: idToken.id, userId: tokenData.sub, token, exp: tokenData.exp};
  }

  //</editor-fold>

  invalidateToken() {
    console.warn("Invalidating Refresh Token");
    this.sessionStorage.tryDelete(EmbeddedAuthService.REFRESH_TOKEN_KEY);
    this.localStorage.tryDelete(EmbeddedAuthService.REFRESH_TOKEN_KEY);
    this.userScope.clearScope();
    this.source.next(this.generateSource());
  }

  logOut(): void {
    console.error(`Can't log out from embedded session`);
  }
}

