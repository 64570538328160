import {Component} from '@angular/core';
import {LoadingService} from "@lib/services/loading.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {'id': 'app-root'}
})
export class AppComponent {

  loading$ = this.loadingService.loading$;

  constructor(private loadingService: LoadingService) {

  }

}
