import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {IdentityService} from "@core/services/identity.service";
import {Timespan} from "@juulsgaard/ts-tools";
import {firstValueFrom} from "rxjs";
import {timeoutError} from "@juulsgaard/rxjs-tools";
import {LoadingService} from "@lib/services/loading.service";
import {RouteService} from "@juulsgaard/ngx-tools";

@Injectable({providedIn: 'root'})
export class IdTokenGuard implements CanActivate {
  constructor(private service: IdentityService, private router: Router, private loadingService: LoadingService, private routeService: RouteService) {
  }

  async canActivate(route: ActivatedRouteSnapshot) {
    this.routeService.prepopulate(route);

    const scope = this.loadingService.startLoad();

    // Get ID token, or timeout after 10 seconds
    const idToken$ = this.service.identityToken$.pipe(
      timeoutError(Timespan.seconds(10), () => Error('No ID Token received'))
    );

    try {
      await firstValueFrom(idToken$);
    } catch (e: any) {
      await this.router.navigate(['error'], {
        skipLocationChange: true,
        queryParams: {reason: e.message}
      });
      return false;
    } finally {
      scope.dispose();
    }

    return true;
  }
}
