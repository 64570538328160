import {ActivatedRouteSnapshot, BaseRouteReuseStrategy, DetachedRouteHandle} from "@angular/router";
import {Injectable} from "@angular/core";

@Injectable()
export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {

  override shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  override store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {

  }

  override shouldAttach(route: ActivatedRouteSnapshot): boolean {
   return false;
  }

  override retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (future.routeConfig !== curr.routeConfig) return false;
    if (!future.data['reload']) return true;
    if (curr.url.length !== future.url.length) return false;
    for (let i = 0; i < curr.url.length; i++) {
      if (curr.url[i]?.path !== future.url[i]?.path) return false;
    }
    return true;
  }

}
