
<router-outlet/>

<ngx-service-worker-alert/>
<ngx-dialog-outlet/>
<ngx-overlay-outlet/>
<ngx-snackbar-outlet/>
<ngx-side-menu-outlet/>

<app-loading-page *ngIf="loading$ | async"/>
